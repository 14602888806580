import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Mobilemenu from "./Mobilemenu";
import PerfectScrollbar from "react-perfect-scrollbar";

import "./NavMenu.css";
import { CartContext } from "../App";
import axios from "axios";
import dummyImageUrl from "../images/placeholder.webp";
export default function Navmenu() {
  const state = useContext(CartContext);

  const navigate = useNavigate();
  const [opensearch, setOpensreach] = useState(false);
  const [showmenu, setShowmenu] = useState(false);

  const [metaTag, setMetaTag] = useState({});
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios
      .get(`${process.env.REACT_APP_BASE_URL}getAllCategory`)
      .then(function (response) {
        // handle success
        if (mounted) {
          setCategories(response.data.data);
          console.log("setCategories", response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    state?.loadCartList();
  }, [state]);

  const getWebsiteInfo = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}getWebsiteInfo`, {
        headers: {
          version: "1.0.0",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        if (response.data.success === false) {
        } else {
          setMetaTag({
            description: response.data.data.description,
            keyword: response.data.data.keyword,
            favicon_icon: response.data.data.favicon_icon,
            title: response.data.data.title,
            logo: response.data.data.logo,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getWebsiteInfo();
    console.log(metaTag);
  }, [categories]);

  const handleImageError = (event) => {
    event.target.src = dummyImageUrl;
  };

  return (
    <div>
      <nav
        className="w-100"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
          paddingBottom: "0",
        }}
      >
        <div className="wrapper">
          <div className="logo">
            <a href="#">
              <img
                src={metaTag.logo}
                alt="logo"
                onClick={() => navigate("/")}
                className="navlogo"
              />
            </a>
          </div>
          <input
            type="radio"
            name="slider"
            className="navinput"
            id="menu-btn"
            onClick={() => setShowmenu(true)}
          />
          <input
            type="radio"
            name="slider"
            className="navinput"
            id="close-btn"
          />
          <ul className="nav-links m-0">
            <label htmlFor="close-btn" className="btn close-btn">
              <span className="material-symbols-outlined">close</span>
            </label>
            <li>
              <Link
                to={`/`}
                className="dottedText"
                style={{ textTransform: "uppercase" }}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to={`/AboutUs`}
                className="dottedText"
                style={{ textTransform: "uppercase" }}
              >
                About Us
              </Link>
            </li>
            {/* {categories.length > 0 &&
              categories.map((item, index) => (
                <>
                  <li key={index}>
                    <a href="#" className="desktop-item dottedText">
                      {item.name}
                    </a>
                    <input type="checkbox" id="showDrop" />
                    <label htmlFor="showDrop" className="mobile-item">
                      {item.name}
                    </label>
                    <ul className="drop-menu">
                      {item.subCategory.length > 0 &&
                        item.subCategory.map((item1, index1) => (
                          <>
                            {item1.status == "Active" && (
                              <>
                                <li key={index1}>
                                  <Link
                                    to={`/child-categories/${item1.slug}`}
                                    style={{ fontSize: "15px" }}
                                  >
                                    {item1.name}
                                  </Link>
                                  <ul>
                                    {item1.childCategory.length > 0 &&
                                      item1.childCategory.map(
                                        (item2, index2) => (
                                          <>
                                            {item2.status == "Active" && (
                                              <>
                                                <li key={index2}>
                                                  <Link
                                                    to={`/child-categories/${item2.slug}`}
                                                    style={{ fontSize: "15px" }}
                                                  >
                                                    {item2.name}
                                                  </Link>
                                                </li>
                                              </>
                                            )}
                                          </>
                                        )
                                      )}
                                  </ul>
                                </li>
                              </>
                            )}
                          </>
                        ))}
                    </ul>
                  </li>
                </>
              ))} */}

            {categories.length > 0 &&
              categories.map((item, index) => (
                <li key={index}>
                  <Link
                    to={`/category/${item.slug}`}
                    className="desktop-item dottedText"
                  >
                    {" "}
                    {item.name}
                  </Link>
                  <input type="checkbox" id="showMega" className="navinput" />
                  <label htmlFor="showMega" className="mobile-item ">
                    {item.name}
                  </label>
                  {item.subCategory.length > 0 ? (
                    <div className="mega-box">
                      <div className="contentdrop">
                        <div className="row w-100">
                          <div className="col-md-7">
                            <ul
                              className="subLinkul"
                              style={{ height: "200px", padding: "20px" }}
                            >
                              <PerfectScrollbar>
                                {item.subCategory.length > 0
                                  ? item.subCategory.map((item1, index1) => (
                                      <li key={index1}>
                                        <Link
                                          to={`/category/${item.slug}/${item1.slug}`}
                                          id="subCategoryLink"
                                        >
                                          {item1.name}
                                        </Link>
                                        <ul
                                          className="subnchildcate"
                                          style={{ height: "100%" }}
                                        >
                                          <PerfectScrollbar>
                                            <li>
                                              <a href="#">
                                                GraphicsBusiness cards
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">Vectors</a>
                                            </li>
                                            <li>
                                              <a href="#">Business cards</a>
                                            </li>
                                            <li>
                                              <a href="#">Custom logo</a>
                                            </li>
                                            <li>
                                              <a href="#">Custom logo</a>
                                            </li>
                                            <li>
                                              <a href="#">Custom logo</a>
                                            </li>
                                          </PerfectScrollbar>
                                        </ul>
                                      </li>
                                    ))
                                  : ""}
                              </PerfectScrollbar>
                            </ul>
                          </div>

                          <div className="col-md-5 d-flex justify-content-center align-items-center p-3">
                            <div>
                              <header
                                style={{
                                  fontSize: "1.5vw",
                                  textAlign: "center",
                                  textTransform: "uppercase",
                                }}
                                className="dottedText"
                              >
                                {item.heading1}
                              </header>
                              <p
                                style={{
                                  lineHeight: "1.3",
                                  textAlign: "center",
                                  padding: "5px 18px ",
                                }}
                                className=""
                              >
                                {item.description}
                              </p>
                              <div className="d-flex justify-content-center">
                                <button
                                  style={{
                                    padding: "7px 13px",
                                    backgroundColor: "#1e3a2e",
                                    color: "#fff",
                                  }}
                                  onClick={() =>
                                    navigate(`/category/${item.slug}`)
                                  }
                                >
                                  DISCOVER MORE
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </li>
              ))}

            {/* <li >
                            <Link to={`/category`} className="desktop-item dottedText"  >dottedText</Link>
                            <input type="checkbox" id="showMega" />
                            <label htmlFor="showMega" className="mobile-item " >dottedText</label>
                            <div className="mega-box">
                                <div className="contentdrop">
                                    <div className='row w-100'>
                                        <div className='col-md-3'>

                                            <ul className='subLinkul' style={{ height: "200px", padding: "20px" }}>
                                                <PerfectScrollbar>



                                                    <li  ><Link to={`/subcategoryview`} id="subCategoryLink">subCategoryLink</Link></li>

                                                    <li  ><Link to={`/subcategoryview`} id="subCategoryLink">subCategoryLink</Link></li>
                                                    <li  ><Link to={`/subcategoryview`} id="subCategoryLink">subCategoryLink</Link></li>


                                                 
                                                </PerfectScrollbar>

                                            </ul>
                                        </div>

                                        <div className="col-md-4" >
                                            <img src="" alt="" className='img-fluid' />
                                        </div>

                                        <div className="col-md-5 d-flex justify-content-center align-items-center p-3">
                                            <div>
                                                <header style={{ fontSize: "35px", textAlign: "center", textTransform: "uppercase" }} className='dottedText' >DISCOVER MORE</header>
                                                <p style={{ lineHeight: "1.3", textAlign: "center", padding: "5px 18px " }} className=''>DISCOVER MOREDISCOVER MOREDISCOVER MOREDISCOVER MOREDISCOVER MOREDISCOVER MORE</p>
                                                <div className='d-flex justify-content-center'>
                                                    <button style={{ padding: "8px 14px", backgroundColor: "#313d4e", color: "#fff" }} >DISCOVER MORE</button>

                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </li>


                        <li >
                            <Link to={`/category`} className="desktop-item dottedText"  >dottedText</Link>
                            <input type="checkbox" id="showMega" />
                            <label htmlFor="showMega" className="mobile-item " >dottedText</label>


                            <div className="mega-box">
                                <div className="contentdrop">
                                    <div className='row w-100'>
                                        <div className='col-md-3'>

                                            <ul className='subLinkul' style={{ maxHeight: "200px", padding: "20px" }}>
                                                <PerfectScrollbar>



                                                    <li  ><Link to={`/subcategoryview`} id="subCategoryLink">subCategoryLink</Link></li>

                                                    <li  ><Link to={`/subcategoryview`} id="subCategoryLink">subCategoryLink</Link></li>
                                                    <li  ><Link to={`/subcategoryview`} id="subCategoryLink">subCategoryLink</Link></li>
                                                    


                                                           </PerfectScrollbar>

                                            </ul>
                                        </div>

                                        <div className="col-md-4" >
                                            <img src="" alt="" className='img-fluid' />
                                        </div>

                                        <div className="col-md-5 d-flex justify-content-center align-items-center p-3">
                                            <div>
                                                <header style={{ fontSize: "35px", textAlign: "center", textTransform: "uppercase" }} className='dottedText' >DISCOVER MORE</header>
                                                <p style={{ lineHeight: "1.3", textAlign: "center", padding: "5px 18px " }} className=''>DISCOVER MOREDISCOVER MOREDISCOVER MOREDISCOVER MOREDISCOVER MOREDISCOVER MORE</p>
                                                <div className='d-flex justify-content-center'>
                                                    <button style={{ padding: "8px 14px", backgroundColor: "#313d4e", color: "#fff" }} >DISCOVER MORE</button>

                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </li> */}
            {/* 
                        <li>
          <a href="#" className="desktop-item">Know us</a>
          <input type="checkbox" id="showDrop" />
          <label htmlFor="showDrop" className="mobile-item">Know us</label>
          <ul className="drop-menu">
            
            <li><a href="#">Sesderma universe</a></li>
            <li><a href="#">News </a></li>
            <li><a href="#">Awards</a></li>
            <li><a href="#">Sesderma TV</a></li>
            <li><a href="#">Work with us</a></li>

          </ul>
        </li> */}

            {/* <li>
            <a href="#" className="desktop-item dottedText" >Body care</a>
            <input type="checkbox" id="showMega" />
            <label htmlFor="showMega" className="mobile-item">Body care</label>
            <div className="mega-box">
              <div className="contentdrop">

                <div className="row">
                  <header>Design Services</header>
                  <ul className="mega-links">

                    <li><a href="#">Graphics</a></li>
                    <li><a href="#">Vectors</a></li>
                    <li><a href="#">Business cards</a></li>
                    <li><a href="#">Custom logo</a></li>
                  </ul>
                </div>
                <div className="row" style={{ width: "40%" }}>
                  <img src="https://fadzrinmadu.github.io/hosted-assets/responsive-mega-menu-and-dropdown-menu-using-only-html-and-css/img.jpg" alt="" />
                </div>

                <div className="row">
                  <header style={{ fontSize: "45px" }}>SOLAR PROTECTION</header>
                  Discover all the promotions of this season. We prepare for you the perfect routines for each of your concerns.

                  <button style={{ padding: "20px", backgroundColor: "#313d4e" }}></button>
                </div>

              </div>
            </div>
          </li> */}

            {/* <li><a href="#">Body care</a></li> */}

            {/* <li>
            <a href="#" className="desktop-item dottedText">Sunscreens</a>
            <input type="checkbox" id="showMega" />
            <label htmlFor="showMega" className="mobile-item">Mega Menu</label>
            <div className="mega-box">
              <div className="contentdrop">

                <div className="row">
                  <header>Design Services</header>
                  <ul className="mega-links">

                    <li><a href="#">Graphics</a></li>
                    <li><a href="#">Vectors</a></li>
                    <li><a href="#">Business cards</a></li>
                    <li><a href="#">Custom logo</a></li>
                  </ul>
                </div>
                <div className="row" style={{ width: "40%" }}>
                  <img src="https://fadzrinmadu.github.io/hosted-assets/responsive-mega-menu-and-dropdown-menu-using-only-html-and-css/img.jpg" alt="" />
                </div>

                <div className="row">
                  <header style={{ fontSize: "45px" }}>SOLAR PROTECTION</header>
                  Discover all the promotions of this season. We prepare for you the perfect routines for each of your concerns.

                  <button style={{ padding: "20px", backgroundColor: "#313d4e" }}></button>
                </div>

              </div>
            </div>
          </li> */}
            {/* <li>
            <a href="#" className="desktop-item dottedText">Packs</a>
            <input type="checkbox" id="showMega" />
            <label htmlFor="showMega" className="mobile-item">Packs</label>
            <div className="mega-box">
              <div className="contentdrop">

                <div className="row">
                  <header>Design Services</header>
                  <ul className="mega-links">

                    <li><a href="#">Graphics</a></li>
                    <li><a href="#">Vectors</a></li>
                    <li><a href="#">Business cards</a></li>
                    <li><a href="#">Custom logo</a></li>
                  </ul>
                </div>
                <div className="row" style={{ width: "40%" }}>
                  <img src="https://fadzrinmadu.github.io/hosted-assets/responsive-mega-menu-and-dropdown-menu-using-only-html-and-css/img.jpg" alt="" />
                </div>
              
                <div className="row">
                  <header style={{ fontSize: "45px" }}>SOLAR PROTECTION</header>
                  Discover all the promotions of this season. We prepare for you the perfect routines for each of your concerns.

                  <button style={{ padding: "20px", backgroundColor: "#313d4e" }}></button>
                </div>

              </div>
            </div>
          </li> */}
            {/* <li><a href="#" className='dottedText' style={{ textTransform: "uppercase" }}>Blog</a></li> */}
            {/* <li>
            <a href="#" className="desktop-item dottedText">Know us</a>
            <input type="checkbox" id="showDrop" />
            <label htmlFor="showDrop" className="mobile-item">Know us</label>
            <ul className="drop-menu">

              <li><a href="#">Sesderma universe</a></li>
              <li><a href="#">News </a></li>
              <li><a href="#">Awards</a></li>
              <li><a href="#">Sesderma TV</a></li>
              <li><a href="#">Work with us</a></li>

            </ul>
          </li> */}
          </ul>
          <div className="d-flex align-items-center ">
            <div className="searchdiv">
              <div
                className={`search-container ${opensearch ? "active" : ""} `}
              >
                <button
                  className="search-btn"
                  onClick={() => navigate("/search-product")}
                >
                  <span className="material-symbols-outlined navuserI">
                    search
                  </span>
                </button>
              </div>
            </div>
            &nbsp;&nbsp;
            {localStorage.getItem("token") ? (
              <>
                {" "}
                <span
                  className="material-symbols-outlined navuserI"
                  onClick={() => navigate("/my-profile")}
                >
                  person
                </span>
                &nbsp;&nbsp;
              </>
            ) : (
              <span
                className="d-flex align-items-center"
                onClick={() => navigate("/sign-in")}
              >
                <span class="material-symbols-outlined navuserI">key</span>
                &nbsp;&nbsp;
              </span>
            )}
            <span
              className="material-symbols-outlined  navcartI"
              onClick={() => navigate("/cart")}
            >
              shopping_cart
            </span>
            <div
              className="cartcountdiv"
              //          style={{position: "relative",
              // width: "23px",
              // height: "20px",
              // color: "#fff"}}
            >
              <span
                className="cartcountspan"

                // style={{    position: "relative",
                //   top: "-89px",
                //   left: "98px",
                //   backgroundColor: "#000",
                //   color: "#fff",
                //   borderRadius: "50%" , padding:"3px "}}
              >
                {state?.cartlen}
              </span>
            </div>
            <label htmlFor="menu-btn" className="btn menu-btn ">
              <span className="material-symbols-outlined navmenuI">menu</span>
            </label>
          </div>
        </div>
      </nav>

      <Mobilemenu show={showmenu} onHide={() => setShowmenu(false)} />
    </div>
  );
}
